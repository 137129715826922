import Vue from 'vue';
import Vuetify, {
  VRow,
  VCol,
  VTextField,
  VTooltip,
  VCheckbox,
  VSelect,
} from 'vuetify/lib';
import 'material-design-icons-iconfont/dist/material-design-icons.css';
import {Ripple, Intersect, Touch, Resize} from 'vuetify/lib/directives';

Vue.use(Vuetify, {
  components: {VRow, VTooltip, VCol, VTextField, VCheckbox, VSelect},
  directives: {Ripple, Intersect, Touch, Resize},
});

export default new Vuetify({
  icons: {
	iconfont: 'mdiSvg', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
  },
  theme: {
	themes: {
	  light: {
		primary: '#fd8500',
		secondary: '#01aef0',
		// accent: '#bead3d',
		// error: '#892301',
		//success: '#26886B',
	  },
	},
  },
});
